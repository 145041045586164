<template>
  <v-form>
    <p class="mb-0">Consider the process depicted in the figure below:</p>

    <p class="mb-2 pl-6">
      <v-img style="width: 550px" :src="imageName" />
    </p>

    <p class="mb-2">
      How does the sign of the free energy change of the system vary with temperature?
    </p>

    <s-textarea v-model="inputs.studentAnswer" outlined counter></s-textarea>

    <ai-loading-overlay v-if="isSubmitting" />
  </v-form>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'uMiamiDemoQuestion1',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/freeEnergyProcess1.png';
    },
  },
};
</script>

<style scoped></style>
